import { Dropdown, Menu, Modal, Input, Button } from 'antd';
import React, { useState, useRef } from 'react';
import lineSpacingIcon from '../../../assets/CustomButton-Icons/line-Spacing.png';
import { useSelector } from 'react-redux';
import { EditorState } from 'draft-js';

const LineSpacingDropdown = ({ handleEditorChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCustomOpen, setIsCustomOpen] = useState(false);
    const [customLineSpacing, setCustomLineSpacing] = useState(1.0);
    let pages = useSelector((state) => state.editor.pages);
    const dropdownRef = useRef(null);

    const handleLineSpacingChange = (value) => {
        setIsCustomOpen(false);
        if (value === 'custom') {
            setIsCustomOpen(true);
            setIsOpen(false);
            return;
        }
        const activePage = pages.find((page) => page.isActive);
        if (activePage) {
            AddLineSpacing(activePage, value);
        }
        setIsOpen(false);
    };

    const handleCustomLineSpacing = () => {
        const activePage = pages.find((page) => page.isActive);
        if (activePage) {
            AddLineSpacing(activePage, customLineSpacing);
        }
        setIsCustomOpen(false);
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => handleLineSpacingChange(1)}>
                Single
            </Menu.Item>
            <Menu.Item onClick={() => handleLineSpacingChange(1.15)}>
                1.15
            </Menu.Item>
            <Menu.Item onClick={() => handleLineSpacingChange(1.5)}>
                1.5
            </Menu.Item>
            <Menu.Item onClick={() => handleLineSpacingChange(2)}>
                Double
            </Menu.Item>
            <Menu.Item onClick={() => handleLineSpacingChange('custom')}>
                Custom Spacing
            </Menu.Item>
        </Menu>
    );

    const AddLineSpacing = (activePage, lineSpacing) => {
        let editorState = activePage.editorState;
        let index = pages.findIndex((page) => activePage.pageId === page.pageId);
        if (index >= 0) {
            const selection = editorState.getSelection();
            const contentState = editorState.getCurrentContent();
            const blockMap = contentState.getBlockMap();
            const startKey = selection.getStartKey();
            const endKey = selection.getEndKey();
            let inSelection = false;

            const updatedBlockMap = blockMap.map((block) => {
                const key = block.getKey();
                if (key === startKey) {
                    inSelection = true;
                }
                if (inSelection) {
                    const blockData = block.getData().merge({ lineHeight: lineSpacing });
                    block = block.merge({ data: blockData });
                }
                if (key === endKey) {
                    inSelection = false;
                }
                return block;
            });
            const updatedContentState = contentState.merge({ blockMap: updatedBlockMap });
            const updatedEditorState = EditorState.push(
                editorState,
                updatedContentState,
                'apply-line-spacing'
            );
            handleEditorChange(index, updatedEditorState);
        }
    }

    return (
        <>
            <Dropdown
                overlay={menu}
                open={isOpen}
                onOpenChange={(visible) => setIsOpen(visible)}
                trigger={['click']}
                placement="bottomLeft"
                getPopupContainer={() => dropdownRef.current}
            >
                <div
                    ref={dropdownRef}
                    title='Line spacing'
                    className='rdw-option-wrapper'
                    onClick={(e) => { setIsOpen(true) }}
                >
                    <img src={lineSpacingIcon} width={18} />
                </div>
            </Dropdown>
            <Modal
                title="Custom Line Spacing"
                visible={isCustomOpen}
                onCancel={() => setIsCustomOpen(false)}
                zIndex={9999}
                footer={[
                    <Button key="back" onClick={() => setIsCustomOpen(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleCustomLineSpacing}>
                        Apply
                    </Button>,
                ]}
                style={{ borderRadius: "8px" }}
            >
                <Input
                    type="number"
                    step="0.01"
                    min="1"
                    value={customLineSpacing}
                    onChange={(e) => setCustomLineSpacing(parseFloat(e.target.value))}
                    placeholder="Enter custom line spacing value"
                />
            </Modal>
        </>
    );
};

export default LineSpacingDropdown;

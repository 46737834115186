import { Select } from "antd";
import React,{useEffect} from "react";
import { TONE_ENUMS } from "./utils.js";

const toneOptions = [
    { value: TONE_ENUMS.FORMAL, label: TONE_ENUMS.FORMAL },
    { value: TONE_ENUMS.INFORMAL, label: TONE_ENUMS.INFORMAL },
    { value: TONE_ENUMS.OPTIMISTIC, label: TONE_ENUMS.OPTIMISTIC },
    { value: TONE_ENUMS.WORRIED, label: TONE_ENUMS.WORRIED },
    { value: TONE_ENUMS.FRIENDLY, label: TONE_ENUMS.FRIENDLY },
    { value: TONE_ENUMS.CURIOUS, label: TONE_ENUMS.CURIOUS },
    { value: TONE_ENUMS.ASSERTIVE, label: TONE_ENUMS.ASSERTIVE },
    { value: TONE_ENUMS.ENCOURAGING, label: TONE_ENUMS.ENCOURAGING },
    { value: TONE_ENUMS.SURPRISED, label: TONE_ENUMS.SURPRISED },
    { value: TONE_ENUMS.COOPERATIVE, label: TONE_ENUMS.COOPERATIVE },
]

const ToneSelect = ({ setTone }) => {
    useEffect(() => {
        return () => {
            setTone("Formal"); // Clear the tone value
        };
    }, []);
    return (
        <Select
            style={{width: "100%",marginTop:10}}
            className="Tone-Select"
            placeholder="Tone"
            onChange={(value) => setTone(value)}
            defaultValue={TONE_ENUMS.FORMAL}
            options={toneOptions}
        />
    )
}
export default ToneSelect;
import React, { useState } from 'react';
import { Modal } from 'antd';
import signatureIcon from '../../../../assets/CustomButton-Icons/signature-Icon.png'
import SignatureModalContent from '../InsertSignatureUsersModal/SignatureModalContent/signatureModalContent';

const SignatureModal = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div>
            <div title='Signature' className='rdw-option-wrapper' onClick={(e) => { showModal(e) }}>
                <img src={signatureIcon} width={18} />
            </div>
            <Modal
                title="Document Signature"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
                zIndex={9999}
                style={{ height: 'max-content' }}
            >
                <SignatureModalContent setIsModalVisible={setIsModalVisible} />
            </Modal>
        </div >
    )
}

export default SignatureModal;
import React from 'react';
import alignCenterIcon from '../../../assets/CustomButton-Icons/AlignmentIcons/center.svg';
import alignLeftIcon from '../../../assets/CustomButton-Icons/AlignmentIcons/left.svg';
import alignRightIcon from '../../../assets/CustomButton-Icons/AlignmentIcons/right.svg';
import alignJustifyIcon from '../../../assets/CustomButton-Icons/AlignmentIcons/justify.svg';
import { EditorState } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsSaving, updatePage } from '../../../app/EditorSlice';
import { useParams } from 'react-router-dom';
import { padServices, workwiseServices } from '../../../api';
import { generateHtml } from '../../../utils/helper';

const TextAlignment = ({ activeEditor }) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    let pages = useSelector((state) => state.editor.pages);

    const handleClick = (alignment) => {
        let activePage = pages.find((page) => page.isActive);
        if (activeEditor) {
            dispatch(updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
            const editorState = activeEditor.editorState;
            const index = activeEditor.editorIndex;
            const selection = editorState.getSelection();
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const block = contentState.getBlockForKey(startKey);

            if (block) {
                const blockData = block.getData().merge({ textAlignment: alignment });
                const newBlock = block.merge({ data: blockData });
                const newContentState = contentState.merge({
                    blockMap: contentState.getBlockMap().set(startKey, newBlock)
                });

                const newEditorState = EditorState.push(
                    editorState,
                    newContentState,
                    'change-block-data'
                );
                dispatch(updatePage({
                    pageId: activePage.pageId,
                    editorState: newEditorState
                }));
                const editorHtml = generateHtml(newContentState);
                workwiseServices.saveDocumentlastUpdate(id, dispatch)
                padServices.saveToDatabase(id, editorHtml, null, dispatch);
            }
        }
    };

    return (
        <div className="rdw-alignment-wrapper" style={{ display: 'flex' }}>
            <div title='Left' className="rdw-option-wrapper" onClick={() => handleClick('left')}>
                <img src={alignLeftIcon} alt="Align Left" />
            </div>
            <div title='Center' className="rdw-option-wrapper" onClick={() => handleClick('center')}>
                <img src={alignCenterIcon} alt="Align Center" />
            </div>
            <div title='Right' className="rdw-option-wrapper" onClick={() => handleClick('right')}>
                <img src={alignRightIcon} alt="Align Right" />
            </div>
            <div title='Justify' className="rdw-option-wrapper" onClick={() => handleClick('justify')}>
                <img src={alignJustifyIcon} alt="Align Justify" />
            </div>
        </div>
    );
};

export default React.memo(TextAlignment);
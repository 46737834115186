import React, { useState } from 'react';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import printIcon from '../../assets/print-icon.png';
import './print.css';
import { useReactToPrint } from 'react-to-print';

const PrintPad = () => {
    const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => document.getElementById('my-editor-main-container'),
        onBeforeGetContent: () => {
            setIsGeneratingPreview(true);
            return new Promise((resolve) => {
                setTimeout(() => {
                    setIsGeneratingPreview(false);
                    resolve();
                }, 1000);
            });
        },
        onAfterPrint: () => { setIsGeneratingPreview(false); }
    });

    return (
        <div>
            <div onClick={handlePrint} style={{ cursor: 'pointer', marginTop: "10px", marginLeft: "8px" }}>
                <img src={printIcon} width={22} alt="Print" />
            </div>
            <Modal
                title="Generating preview"
                visible={isGeneratingPreview}
                closable={false}
                footer={null}
                width={240}
                zIndex={9999}
                centered
                style={{ borderRadius: '8px', width: '150px' }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ marginBottom: '10px' }}>This may take a few moments</p>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 28,
                                    fontWeight: 'bold',
                                    color: 'black'
                                }}
                                spin
                            />
                        }
                    />
                </div>
            </Modal>
        </div>
    );
};

export default PrintPad;
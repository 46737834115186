import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const hardcodeToken = null;

function getCookieValue(name) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  if (match) {
    return match[2]
  }
}

export function getWorkWiseAuthToken() {
  const searchParams = new URLSearchParams(document.location.search);
  let cookieToken = getCookieValue("token")
  let queryToken = searchParams.get("token");
  const finalToken = queryToken || cookieToken || hardcodeToken;
  return finalToken;
}

export const generateHtml = (contentState) => {
  let rawContentState = convertToRaw(contentState);
  rawContentState.blocks = rawContentState.blocks.map(block => {
    if (block.type.startsWith('header')) {
      if (!block.data.id) {
        block.data.id = `heading-${block.key}`;
      }
    }
    const newData = {
      ...block.data,
      ...(block.data.lineHeight ? { lineHeight: block.data.lineHeight } : {}),
      ...(block.data.textAlignment ? { 'text-align': block.data.textAlignment } : {}),
    };
    return {
      ...block,
      data: newData
    };
  });
  // Convert to HTML
  const htmlWithIDs = draftToHtml(rawContentState);
  return htmlWithIDs;
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0";

  const units = ["Bytes", "KB", "MB", "GB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  if (unitIndex === 0) {
    return `${size} ${units[unitIndex]}`;
  } else if (unitIndex === 1 && size < 50) {
    return `${parseFloat(size.toFixed(0))} ${units[unitIndex]}`;
  } else {
    return `${parseFloat(size.toFixed(2))} ${units[unitIndex]}`;
  }
};
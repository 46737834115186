import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import JSZip from 'jszip';
import htmlToDraft from 'html-to-draftjs';

export const convertDocxToHtml = async (arrayBuffer) => {
    try {
        const zip = new JSZip();
        const content = await zip.loadAsync(arrayBuffer);
        const documentXml = await content.file('word/document.xml').async('text');
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(documentXml, 'application/xml');
        const body = xmlDoc.getElementsByTagName('w:body')[0];
        // console.log('okkkk body', body);
        let html = '';
        let currentList = null;
        let currentListLevel = -1;

        const processElement = async (element) => {

            let elementHtml = '';
            if (element.tagName === 'w:p') {
                const styleElement = element.getElementsByTagName('w:pStyle')[0];
                const styleId = styleElement ? styleElement.getAttribute('w:val') : null;
                const numPrElement = element.getElementsByTagName('w:numPr')[0];
                let tagName = 'p';
                let styleAttributes = '';
                if (numPrElement) {
                    const ilvlElement = numPrElement.getElementsByTagName('w:ilvl')[0];
                    const numIdElement = numPrElement.getElementsByTagName('w:numId')[0];
                    const ilvl = ilvlElement ? parseInt(ilvlElement.getAttribute('w:val'), 10) : 0;
                    const numId = numIdElement ? numIdElement.getAttribute('w:val') : null;
                    const listType = numId % 2 === 0 ? 'ol' : 'ul';

                    elementHtml += `<li>`;
                    const childElements = element.childNodes;
                    for (let j = 0; j < childElements.length; j++) {
                        const childElement = childElements[j];
                        if (childElement.tagName === 'w:r') {
                            elementHtml += await processRun(childElement);
                        } else if (childElement.tagName === 'w:drawing') {
                            elementHtml += await processDrawing(childElement, content);
                        } else {
                            elementHtml += await processElement(childElement);
                        }
                    }
                    elementHtml += '</li>';
                }
                else {
                    if (styleId) {
                        switch (styleId) {
                            case 'Heading1':
                                tagName = 'h1';
                                break;
                            case 'Heading2':
                                tagName = 'h2';
                                break;
                            case 'Heading3':
                                tagName = 'h3';
                                break;
                            case 'Heading4':
                                tagName = 'h4';
                                break;
                            case 'Heading5':
                                tagName = 'h5';
                                break;
                            case 'Heading6':
                                tagName = 'h6';
                                break;
                            case 'Heading7':
                                tagName = 'h7';
                                break;
                            // Add more cases as needed
                        }
                    }
                    const alignmentElement = element.getElementsByTagName('w:jc')[0];
                    const alignment = alignmentElement ? alignmentElement.getAttribute('w:val') : 'left';
                    const lineHeight = getLineHeight(element) || null;
                    styleAttributes += `text-align:${alignment};`;

                    if (lineHeight) {
                        styleAttributes += `line-height:${lineHeight}px;`;
                    }
                    elementHtml = `<${tagName} style="${styleAttributes}" data-alignment="${alignment}" data-line-height="${lineHeight}">`;
                    const childElements = element.childNodes;
                    for (let j = 0; j < childElements.length; j++) {
                        const childElement = childElements[j];
                        if (childElement.tagName === 'w:r') {
                            elementHtml += await processRun(childElement);
                        } else if (childElement.tagName === 'w:drawing') {
                            elementHtml += await processDrawing(childElement, content);
                        } else {
                            elementHtml += await processElement(childElement);
                        }
                    }
                    elementHtml += `</${tagName}>`;

                }
            } else if (element.tagName === 'w:tbl') {
                // Table handling
                elementHtml = await processTable(element, processElement);
            } else if (element.tagName === 'w:hyperlink') {
                const rId = element.getAttribute('r:id');
                const relationship = await content.file(`word/_rels/document.xml.rels`).async('text');
                const relsDoc = parser.parseFromString(relationship, 'application/xml');
                const target = relsDoc.querySelector(`Relationship[Id="${rId}"]`).getAttribute('Target');
                elementHtml = `<a href="${target}">`;
                const runs = element.getElementsByTagName('w:r');
                for (let j = 0; j < runs.length; j++) {
                    const run = runs[j];
                    elementHtml += await processRun(run);
                }
                elementHtml += '</a>';
            }

            return elementHtml;
        };


        const processDrawing = async (drawingElement, content) => {
            let drawingHtml = '';

            const blipElements = drawingElement.getElementsByTagName('a:blip');
            for (let i = 0; i < blipElements.length; i++) {
                const blipElement = blipElements[i];
                const embedId = blipElement.getAttribute('r:embed');
                const relationshipXml = await content.file('word/_rels/document.xml.rels').async('text');
                const relsDoc = parser.parseFromString(relationshipXml, 'application/xml');
                const rel = relsDoc.querySelector(`Relationship[Id="${embedId}"]`);
                if (rel) {
                    const target = rel.getAttribute('Target');
                    const imageFile = content.file(`word/${target}`);
                    if (imageFile) {
                        const imageBase64 = await imageFile.async('base64');
                        const extension = target.split('.').pop();
                        const imageUrl = `data:image/${extension};base64,${imageBase64}`;

                        // Get image size and transformations
                        const extentElement = drawingElement.getElementsByTagName('wp:extent')[0];
                        const cx = extentElement ? extentElement.getAttribute('cx') : null;
                        const cy = extentElement ? extentElement.getAttribute('cy') : null;

                        // Convert EMU to pixels (1 EMU = 1/914400 inches, 96 DPI)
                        const emuToPx = (emu) => Math.round((parseInt(emu, 10) / 914400) * 96);
                        const width = cx ? emuToPx(cx) : null;
                        const height = cy ? emuToPx(cy) : null;

                        // Get image rotation
                        const xfrmElement = drawingElement.getElementsByTagName('a:xfrm')[0];
                        const rotation = xfrmElement ? xfrmElement.getAttribute('rot') : null;
                        const rotationDeg = rotation ? parseInt(rotation, 10) / 60000 : 0;

                        let style = '';
                        if (width) style += `width: ${width}px;`;
                        if (height) style += `height: ${height}px;`;
                        if (rotationDeg) style += `transform: rotate(${rotationDeg}deg);`;

                        drawingHtml = `<img src="${imageUrl}" style="${style}" />`;
                    }
                }
            }

            return drawingHtml;
        };

        const processRun = async (run) => {
            let runHtml = '';
            let textContent = '';
            const childElements = run.childNodes;
            for (let i = 0; i < childElements.length; i++) {
                const childElement = childElements[i];
                if (childElement.tagName === 'w:t') {
                    textContent += childElement.textContent;
                } else if (childElement.tagName === 'w:drawing') {
                    runHtml += await processDrawing(childElement, content);
                }
            }

            if (textContent) {
                const bold = run.getElementsByTagName('w:b').length > 0;
                const italic = run.getElementsByTagName('w:i').length > 0;
                const underline = run.getElementsByTagName('w:u').length > 0;
                const strike = run.getElementsByTagName('w:strike').length > 0;
                const color = run.getElementsByTagName('w:color')[0]?.getAttribute('w:val');
                const fontSize = run.getElementsByTagName('w:sz')[0]?.getAttribute('w:val');
                const fontFamilyElement = run.getElementsByTagName('w:rFonts')[0];
                const fontFamily = fontFamilyElement?.getAttribute('w:ascii') || fontFamilyElement?.getAttribute('w:hAnsi') || fontFamilyElement?.getAttribute('w:cs');
                const highlight = run.getElementsByTagName('w:highlight')[0]?.getAttribute('w:val');
                const monospace = run.getElementsByTagName('w:rFonts')[0]?.getAttribute('w:ascii') === 'Courier New';
                const superscript = run.getElementsByTagName('w:vertAlign')[0]?.getAttribute('w:val') === 'superscript';
                const subscript = run.getElementsByTagName('w:vertAlign')[0]?.getAttribute('w:val') === 'subscript';

                let style = '';
                if (bold) style += 'font-weight: bold;';
                if (italic) style += 'font-style: italic;';
                if (underline) style += 'text-decoration: underline;';
                if (strike) style += 'text-decoration: line-through;';
                if (color) style += `color: #${color};`;
                if (fontSize) style += `font-size: ${fontSize / 2}px;`;
                if (fontFamily) style += `font-family: '${fontFamily}';`;
                if (highlight) style += `background-color: ${highlight};`;
                if (monospace) style += 'font-family: monospace;';

                if (style || superscript || subscript) {
                    let formattedText = textContent;
                    if (superscript) {
                        formattedText = `<sup>${formattedText}</sup>`;
                    } else if (subscript) {
                        formattedText = `<sub>${formattedText}</sub>`;
                    }
                    runHtml += `<span style="${style}">${formattedText}</span>`;
                } else {
                    runHtml += textContent;
                }
            }

            return runHtml;
        };

        const processElementWithListContext = async (element) => {
            let elementHtml = '';
            if (element.tagName === 'w:p') {
                const numPrElement = element.getElementsByTagName('w:numPr')[0];
                if (numPrElement) {
                    const ilvlElement = numPrElement.getElementsByTagName('w:ilvl')[0];
                    const numIdElement = numPrElement.getElementsByTagName('w:numId')[0];
                    const ilvl = ilvlElement ? parseInt(ilvlElement.getAttribute('w:val'), 10) : 0;
                    const numId = numIdElement ? numIdElement.getAttribute('w:val') : null;
                    const listType = numId % 2 === 0 ? 'ol' : 'ul';

                    if (!currentList || currentListLevel !== ilvl) {
                        // console.log('okkkk listtype', listType, numId);
                        if (currentList) {
                            elementHtml += '</li>'.repeat(currentListLevel + 1);
                            elementHtml += `</${currentList}>`.repeat(currentListLevel + 1);
                        }
                        elementHtml += `<${listType}>`.repeat(ilvl + 1);
                        currentList = listType;
                        currentListLevel = ilvl;
                    } else if (currentListLevel === ilvl) {
                        elementHtml += '</li>';
                    }

                    elementHtml += await processElement(element);
                } else {
                    if (currentList) {
                        elementHtml += '</li>'.repeat(currentListLevel + 1);
                        elementHtml += `</${currentList}>`.repeat(currentListLevel + 1);
                        currentList = null;
                        currentListLevel = -1;
                    }
                    elementHtml += await processElement(element);
                }
            } else {
                elementHtml += await processElement(element);
            }
            return elementHtml;
        };

        const childElements = body.childNodes;
        for (let i = 0; i < childElements.length; i++) {
            html += await processElementWithListContext(childElements[i]);
        }

        if (currentList) {
            html += '</li>'.repeat(currentListLevel + 1);
            html += `</${currentList}>`.repeat(currentListLevel + 1);
        }
        return html;
    } catch (error) {
        console.error('okkkError converting DOCX to HTML:', error);
        return  null;
    }
};

const getLineHeight = (element) => {
    const lineHeightTwentieths = element.getElementsByTagName('w:spacing')[0]?.getAttribute('w:line');
    if (!lineHeightTwentieths) return '';
    const val = parseInt(lineHeightTwentieths, 10);
    // DOCX stores line height in twentieths of a point
    const lineHeightPoints = val / 20;
    // Convert to a relative value (assuming 12pt as base font size)
    const relativeLineHeight = lineHeightPoints / 12;
    // Round to two decimal places
    return relativeLineHeight.toFixed(2);
};

const processTable = async (element, processElement) => {
    let elementHtml = '<table style="border-collapse: collapse; border: 1px solid black;">';
    // Process rows
    const rows = element.getElementsByTagName('w:tr');
    for (let j = 0; j < rows.length; j++) {
        const row = rows[j];
        elementHtml += '<tr>';
        // Process cells in the row
        const cells = row.getElementsByTagName('w:tc');
        for (let k = 0; k < cells.length; k++) {
            const cell = cells[k];
            elementHtml += '<td style="border: 1px solid black; padding: 5px;">';
            // Process paragraphs in the cell
            const paragraphs = cell.getElementsByTagName('w:p');
            for (let l = 0; l < paragraphs.length; l++) {
                const paragraph = paragraphs[l];
                elementHtml += await processElement(paragraph);
            }

            elementHtml += '</td>';
        }
        elementHtml += '</tr>';
    }
    elementHtml += '</table>';
    return elementHtml;
};

export const createEditorStateFromDocx = async (file) => {
    try {
        const arrayBuffer = await file.arrayBuffer();
        const docHtml = await convertDocxToHtml(arrayBuffer);

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = docHtml;

        const elements = tempDiv.querySelectorAll('*[data-alignment], *[data-line-height]');
        const dataAttributes = Array.from(elements).map(el => ({
            alignment: el.getAttribute('data-alignment'),
            lineHeight: el.getAttribute('data-line-height')
        }));

        const blocksFromHTML = htmlToDraft(docHtml);
        let contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

        contentState = blocksFromHTML.contentBlocks.reduce((updatedState, block, index) => {
            const { alignment, lineHeight } = dataAttributes[index] || {};
            if (!alignment && !lineHeight) return updatedState;
            // console.log('okkkk innnn',dataAttributes[index],'\nblock',block.toJS());
            const selection = SelectionState.createEmpty(block.getKey());
            const newData = {};
            if (alignment) newData.textAlignment = alignment;
            if (lineHeight) newData.lineHeight = lineHeight;

            return Modifier.mergeBlockData(updatedState, selection, newData);
        }, contentState);

        return {editorState:EditorState.createWithContent(contentState),html:docHtml};

    } catch (error) {
        return null;
    }
};

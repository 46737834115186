import { message, Upload, Button, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React from 'react';
import { createEditorStateFromDocx } from '../DocToHtml';

const FileInput = ({ onFileSelect }) => {
  const handleFileChange = async (info) => {
    if (info.file.status === 'done') {
      // Check if the file was uploaded successfully
      const file = info.file.originFileObj;
          // Check if a file is selected
    if (file) {
        // Extract file extension
        const fileExtension = file.name.split('.').pop().toLowerCase();
  
        // Define a list of allowed extensions (e.g., text files)
        const allowedExtensions = ['txt', 'md', 'doc', 'docx','rtf']; // Add your allowed extensions
        const editorData = await createEditorStateFromDocx(file);
  
        // Check if the file extension is allowed
        if (allowedExtensions.includes(fileExtension) && editorData) {
          // Call the onFileSelect callback with the valid file
            onFileSelect(file);
          // Show an error message for invalid file extensions
            message.success('File Imported Successfully');
        } else {
          // Show an error message for invalid file extensions
          message.error('Invalid file extension. Please select a valid text file.');
          // You might want to provide a more user-friendly way to display the error.
        }
      }
    } else if (info.file.status === 'error') {
      // Handle the file upload error
      message.error('File upload failed.');
    }
  };

  const customRequest = ({ onSuccess, onError, file }) => {
      // Assume the file was uploaded successfully
      onSuccess();
  };

  return (
    <Space>
      <Upload
        customRequest={customRequest}
        onChange={handleFileChange}
        showUploadList={false} // Hide the file list
      >
        <Button icon={<UploadOutlined />}>Choose File</Button>
      </Upload>
    </Space>
  );
};

export default FileInput;

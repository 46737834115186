import axios from "axios";

export const generateAiText = async (aiQuestion) => {
    let response;
    let error;
    try {
        response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                messages: [
                    { role: 'system', content: aiQuestion },
                ],
                model: 'gpt-3.5-turbo',
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${"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"}`,
                },
            }
        );
    } catch (err) {
        error = err
        if(error?.response?.status === 401){
            error.message = "Authentication failed"
        }
        console.log("ERROR", error)
    }


    return {
        status: !!response?.data?.choices?.[0]?.message?.content,
        message: error?.message || "",
        data: response?.data?.choices?.[0]?.message?.content
    }
}
import React,{useState} from "react";
import { Menu, Dropdown,Button } from "antd";
import classes from "./NavTab.module.css";
import SubMenu from "antd/lib/menu/SubMenu";
import { MenuItem } from "rc-menu";
import { useDispatch,useSelector } from "react-redux";
import { updateIsExport,updateIsFileChoose,chooseFile } from "../../app/EditorSlice";
import FileInput from "../FileInput/FileInput";
import { EditorState, convertToRaw, RichUtils, Modifier, ContentState, convertFromHTML } from 'draft-js';

export const NavTab = () => {
  const isReadOnly = useSelector((state)=>state.editor.isReadOnly);
  const editorState_=useSelector((state)=>state.editor.editorState)
  const [editorState, setEditorState] = useState(editorState_);

  const handleFileSelect = (file) => {
    dispatch(chooseFile(file));

    // Use FileReader API to read the file content
    const reader = new FileReader();
    reader.onload = (e) => {
      // Get the content of the file
      const fileContent = e.target.result;

    const contentState = ContentState.createFromText(fileContent);
    

  };
    // console.log({editorState});
    // Read the file as text
    reader.readAsText(file);
    dispatch(updateIsFileChoose(true))
  };
  const dispatch=useDispatch();
  const menu = (
    <Menu>
      <SubMenu key="sub1" title="Import File">
      <FileInput onFileSelect={handleFileSelect} /> 
      </SubMenu>
      <SubMenu key="sub2" title="Export As">
        <MenuItem key="sub2.1" onClick={() => dispatch(updateIsExport('txt'))}>Export (.txt)</MenuItem>
        <MenuItem key="sub2.4" onClick={() => dispatch(updateIsExport('html'))}>Export (.html)</MenuItem>
        <MenuItem key="sub2.5" onClick={() => dispatch(updateIsExport('pdf'))}>Export (.pdf)</MenuItem>
      </SubMenu>
    </Menu>
  );

  return (
    <>{!isReadOnly &&
      <>
      <Dropdown overlay={menu} trigger={["click"]}  arrow>
        <div
          className={classes.navTabButton}
          onClick={(e) => e.preventDefault()}
        >
          Import/Export
        </div>
      </Dropdown>
      
        {/* <div
          className={classes.navTabButton}
          
        >
          Edit
        </div>
      
        <div
          className={classes.navTabButton}
          
        >
          View
        </div>

      
        <div
          className={classes.navTabButton}
          
        >
          Insert
        </div>

      
        <div
          className={classes.navTabButton}
          
        >
          Format
        </div> */}
        </>
}
    </>
  );
};

import { AutoComplete, Avatar, Button, Form, Image, Space, message } from 'antd';
import { debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import { padServices, workwiseServices } from '../../../../../api';
import defaultProfileImage from '../../../../../assets/defult-profile-logo.png';
import '../SignatureModalContent/signatureContent.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadSignatures, updateIsSaving } from '../../../../../app/EditorSlice';
import { useParams } from 'react-router-dom';

const SignatureModalContent = ({ setIsModalVisible }) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const [selectedItems, setSelectedItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedMember, setSelectedMember] = useState({});
    let pages = useSelector((state) => state.editor.pages);

    const handleSearch = (value) => {
        if (value !== '') {
            const payload = {
                notRequired: [],
                pageNo: 1,
                pageSize: 20,
                referenceId: '00000000-0000-0000-0000-000000000000',
                removeSelf: false,
                search: value,
                types: [1],
            }
            debouncedGetAllSuggestions(payload);
        }
        else {
            debouncedGetAllSuggestions.cancel();
            setOptions([]);
        }
    };
    const debouncedGetAllSuggestions = useRef(
        debounce(async (payload) => {
            try {
                const res = await workwiseServices.getAllSuggestion(payload);
                const data = res.data.data;
                const optionsData = data.map((member) => ({
                    key: member.id,
                    value: member.name,
                    email: member.email,
                    designation: member.designation,
                    label: (
                        <div className="member-option" style={{ display: 'flex', gap: 8 }}>
                            <div>
                                <Avatar
                                    size="large"
                                    src={
                                        member.image ? (
                                            <Image src={member.image} />
                                        ) : (
                                            <Image
                                                src={defaultProfileImage}
                                                style={{ width: '35px' }}
                                            />
                                        )
                                    }
                                />
                            </div>
                            <div className="member-info">
                                <span className="name" style={{ fontWeight: '500' }}>
                                    {member.name}
                                </span>
                                <br />
                                <span className="designation">{member.designation}</span>
                            </div>
                        </div>
                    ),
                }));
                setOptions(optionsData);
            }
            catch (e) {

            }
        }, 300)
    ).current;

    const handleSelect = (value) => {
        const selectedUser = options.find((user) => user.value === value);
        if (selectedUser) {
            let payload = {
                id: selectedUser.key,
                name: selectedUser.value,
                designation: selectedUser.designation,
                email: selectedUser.email,
            }
            setSelectedMember(payload);
        }
    };

    const handleChange = (value) => {
        setSelectedItems(value);
    };

    const filteredOptions = options.filter(
        (option) => !selectedItems.includes(option.value)
    );

    const handleClick = () => {
        addToPage();
    };

    const addToPage = async () => {
        const activePage = pages.find((page) => page.isActive);
        const activePageIndex = activePage && pages.findIndex((page) => page.pageId === activePage.pageId);
        
        if (activePage && activePageIndex >= 0) {
            const payload = {
                id: selectedMember.id,
                name: selectedMember.name,
                designation: selectedMember.designation,
                pageIndex: activePageIndex,
                style: {
                    top: 1020,
                    left: 620
                },
            };
            try {
                dispatch(updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
                const response = await padServices.addSignature({ ...payload, documentId: id },dispatch);
                const signatures = response?.data?.Signatures || [];
                dispatch(loadSignatures(signatures));
                message.success('Signature added successfully');
                setIsModalVisible(false);
                let signatureContainer = signatures.length > 0 && document.getElementById(signatures[signatures.length - 1]._id) || null;
                if (signatureContainer) {
                    signatureContainer.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                }
            } catch (error) {
                message.error('Something went wrong...');
                console.error('Error adding signature:', error);
            }

        }
    };

    return (
        <div>
            <Form>
                <Form.Item label='Users:'>
                    <Space style={{ width: '100%' }} direction="vertical">
                        <AutoComplete
                            // mode="multiple"
                            allowClear
                            value={selectedItems}
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            onChange={handleChange}
                            options={filteredOptions}
                            onSearch={handleSearch}
                            onSelect={handleSelect}
                            notFoundContent={'No data'}
                        />
                    </Space>
                </Form.Item>
                <Form.Item>
                    <Button style={{ float: "right", backgroundColor: '#526bb1', color: 'white' }}
                        onClick={handleClick}>Place Signature</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default SignatureModalContent;
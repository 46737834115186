import React, { useState, useRef, useEffect } from 'react';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import { useParams } from 'react-router-dom';
import { padServices, workwiseServices } from '../../api';
import { EditorState, convertToRaw, Modifier, ContentState, convertFromHTML, convertFromRaw, getDefaultKeyBinding, SelectionState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { addPage, deletePage, insertEditorInstancesDelta, loadSignatures, setActivePage, setEditorRef, setPages, updateIsExport, updateIsFileChoose, updateIsSaving, updatePage, updatePages, updateSignature } from '../../app/EditorSlice';
import { useSelector, useDispatch } from 'react-redux';
import classes from '../editor/Editor.module.css';
import { stateToHTML } from 'draft-js-export-html';
import './Editor.css';
import { debounce, indexOf } from "lodash";
import { emitLoadData, listenLoadData } from '../../Socket';
import AiTextButton from '../AiTextButton/AiTextButton';
import { Button, message, Popconfirm } from 'antd';
import { ReactComponent as Ai } from '../../assets/ai_post.svg';
import { v4 } from 'uuid';
import deleteIcon from '../../assets/delete-icon.png';
import Signatures from '../CustomButtons/DocumentSignature/signatures.js';
import htmlToDraft from 'html-to-draftjs';
import SignatureModal from "../CustomButtons/DocumentSignature/InsertSignatureUsersModal/signatureModal.js";
import LineSpacingDropdown from '../CustomButtons/LineSpacing/lineSpacingDropdown.js';
import TextAlignment from '../CustomButtons/Alignment/alignment.js';
import { convertDocxToHtml, createEditorStateFromDocx } from '../DocToHtml/index.js';
import draftToHtml from 'draftjs-to-html';
import { generateHtml } from '../../utils/helper.js';

const RichTextEditor = () => {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const editorIdArr = useSelector((state) => state.editor.editorInstancesIds);
  const milepadData = useSelector((state) => state.editor.editorInstancesDelta);
  const isReadOnly = useSelector((state) => state.editor.isReadOnly);
  const documentInformation = useSelector((state) => state.editor.documentInformation);
  // const [editorState, setEditorState] = useState(useSelector((state) => state.editor.editorState)); // Access EditorState from Redux
  const user = useSelector(state => state.editor.documentInformation)
  // console.log('user',user);
  const doExport = useSelector((state) => state.editor.isExport)
  const doFileChoose = useSelector((state) => state.editor.isFileChoose)
  const file = useSelector((state) => state.editor.file)
  const { id } = useParams();
  const [isDidmount, setIsDidmount] = useState(true)
  const [showAi, setShowAi] = useState(false)
  const [aiModalVisibility, setAiModalVisibility] = useState(false)
  const [isOpenAi, setIsOpenAi] = useState(false);
  const [isUserChange, setIsUserChange] = useState(false);
  let pages = useSelector((state) => state.editor.pages);
  const [editorState, setEditorState] = useState(pages[0].editorState);
  const userInformation = useSelector((state) => state.editor.userInformation);
  const [isDeleteKeyPressed, setIsDeleteKeyPressed] = useState(false);
  const [activeEditor, setActiveEditor] = useState({});

  useEffect(() => {
    listenLoadData(null, () => {
      setIsUserChange(false)
      // alert()
      fetchDocument()
    })
  }, [])

  useEffect(() => {
    fetchDocument();
    return () => {
      debouncedSaveToDatabase.cancel();
    };
  }, [id]);

  const debouncedSaveToDatabase = useRef(debounce((html, isChangeByUser) => {
    if (isChangeByUser) {
      padServices.saveToDatabase(id, html, null, dispatch);
      emitLoadData(null)
    }
  }, 500)).current;

  const fetchDocument = async () => {
    try {
      const response = await padServices.fetchById(id);
      if (response) {
        if (!response.data) {
          await padServices.saveToDatabase(id, '');
          return;
        }
        if (response.data?.milepadID) {
          const htmlContent = await preprocessHtml(response.data.milepadData || "");
          const contentState = convertHtmlToDraftContentState(htmlContent);
          const initialContent = EditorState.createWithContent(contentState);
          dispatch(updatePage({
            pageId: pages[0].pageId,
            editorState: initialContent
          }));
          let pagesArray = [...pages];
          pagesArray[0] = { ...pagesArray[0], editorState: initialContent };
          distributeContentAcrossPages(0, initialContent, pagesArray);
          dispatch(loadSignatures(response.data.Signatures || []));
        }
      }
    } catch (error) {
      console.error('okkkError fetching document:', error);
    }
  };

  const convertHtmlToDraftContentState = (html) => {
    const blocksFromHTML = htmlToDraft(html);
    let contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    contentState = blocksFromHTML.contentBlocks.reduce((updatedState, block) => {
      const blockData = block.getData();
      const alignment = blockData.get('text-align') || null;
      if (!alignment) return updatedState;
      const selection = SelectionState.createEmpty(block.getKey());
      const newData = {};
      if (alignment) newData.textAlignment = alignment;
      return Modifier.mergeBlockData(updatedState, selection, newData);
    }, contentState);
    return contentState;
  };

  const preprocessHtml = async (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const figureElements = doc.querySelectorAll('figure');
    figureElements.forEach(figure => {
      const h1 = document.createElement('p');
      h1.textContent = '';
      figure.replaceWith(h1);
    });
    return doc.body.innerHTML;
  };


  const distributeContentAcrossPages = (pageIndex, editorState, pagesArray) => {
    let currentPageIndex = pageIndex;
    let currentEditorState = editorState;
    let overflowData = detectOverflow(convertToRaw(currentEditorState.getCurrentContent()), pagesArray[currentPageIndex].pageId);

    while (overflowData && overflowData.overflowBlocks.length > 0) {
      let nextPageIndex = currentPageIndex + 1;
      let nextPage = pagesArray[nextPageIndex] || null;
      if (!nextPage) {
        let { currentEditorState: updatedCurrentEditorState } = updatedCurrentState(currentPageIndex, overflowData, pagesArray);
        pagesArray[currentPageIndex] = { ...pagesArray[currentPageIndex], editorState: updatedCurrentEditorState };
        dispatch(updatePage({
          pageId: pagesArray[currentPageIndex].pageId,
          editorState: updatedCurrentEditorState
        }));
        nextPage = addNewPageReload({
          blocks: overflowData.overflowBlocks,
          entityMap: {}
        });
        pagesArray.push(nextPage);
        nextPageIndex = pagesArray.length - 1;
      }
      currentPageIndex = nextPageIndex;
      currentEditorState = pagesArray[currentPageIndex].editorState;
      overflowData = detectOverflow(convertToRaw(currentEditorState.getCurrentContent()), pagesArray[currentPageIndex].pageId);
    }
    let combinedHtml = '';
    for (let i = 0; i < pagesArray.length; i++) {
      let page = pagesArray[i];
      const contentState = page.editorState.getCurrentContent();
      const pageHtml = generateHtml(contentState);
      combinedHtml += pageHtml;
    }
    dispatch(insertEditorInstancesDelta(combinedHtml));
  };
  const updatedCurrentState = (currentPageIndex, overflowData, pagesArray) => {
    const currentPage = pagesArray[currentPageIndex];
    let currentEditorState = EditorState.createWithContent(
      convertFromRaw({
        blocks: overflowData.visibleBlocks,
        entityMap: convertToRaw(currentPage.editorState.getCurrentContent()).entityMap,
      })
    );
    return { currentEditorState };
  };

  const addNewPageReload = (initialContent = null) => {
    const newPage = {
      pageId: `page-${v4()}`,
      editorState: initialContent ? EditorState.createWithContent(convertFromRaw(initialContent)) : EditorState.createEmpty(),
      isActive: false,
    };
    dispatch(addPage(newPage));
    return newPage;
  };

  const handleEditorChange = (editorIndex, newEditorState) => {
    const pageId = pages[editorIndex].pageId;
    setActiveEditor({
      pageId: pageId,
      editorState: newEditorState,
      editorIndex: editorIndex
    });
    dispatch(updatePage({
      pageId: pageId,
      editorState: newEditorState
    }));
    setIsUserChange(true);
    const currentContent = pages[editorIndex].editorState.getCurrentContent();
    const newContent = newEditorState.getCurrentContent();
    const contentHasChanged = !currentContent.equals(newContent);
    const updatedEditors = [...pages];
    updatedEditors[editorIndex] = { ...updatedEditors[editorIndex], editorState: newEditorState };
    setEditorState(newEditorState);

    const rawContent = convertToRaw(newEditorState.getCurrentContent());
    let overflowData = detectOverflow(rawContent, updatedEditors[editorIndex].pageId);
    let isEditorEmpty = newEditorState.getCurrentContent().getPlainText().trim().length === 0;
    const beforeDeleteBlockMap = currentContent.getBlockMap();
    const beforeDeleteBlocksLength = beforeDeleteBlockMap.size;
    const afterDeleteBlocksLength = newContent.getBlockMap().size;

    if (isEditorEmpty) {
      let nextpage = updatedEditors[editorIndex + 1] || null;
      if (nextpage && overflowData && overflowData.overflowBlocks.length === 0) {
        moveDataToPrevPage(editorIndex, overflowData, updatedEditors, rawContent);
      }
    } else if (overflowData && overflowData.overflowBlocks.length === 0 &&
      isDeleteKeyPressed && beforeDeleteBlocksLength > afterDeleteBlocksLength) {
      moveDataToPrevPage(editorIndex, overflowData, updatedEditors, rawContent);
    } else if (overflowData && overflowData.overflowBlocks.length > 0) {
      let currentPageIndex = editorIndex;
      let currentPage = updatedEditors[currentPageIndex];
      let currentPageRawContent = convertToRaw(currentPage.editorState.getCurrentContent());
      let currentPageOverflowData = detectOverflow(currentPageRawContent, currentPage.pageId);

      while (currentPageOverflowData && currentPageOverflowData.overflowBlocks.length > 0) {
        const nextPageIndex = currentPageIndex + 1;
        const nextPage = updatedEditors[nextPageIndex] || null;
        if (nextPage) {
          let nextPageRawContent = convertToRaw(nextPage.editorState.getCurrentContent());
          let currentEditorState = EditorState.createWithContent(
            convertFromRaw({
              blocks: currentPageOverflowData.visibleBlocks,
              entityMap: currentPageRawContent.entityMap,
            })
          );
          let nextEditorState = EditorState.createWithContent(
            convertFromRaw({
              blocks: [...currentPageOverflowData.overflowBlocks, ...nextPageRawContent.blocks],
              entityMap: nextPageRawContent.entityMap,
            })
          );
          updatedEditors[currentPageIndex] = { ...updatedEditors[currentPageIndex], editorState: currentEditorState };
          dispatch(updatePage({
            pageId: updatedEditors[currentPageIndex].pageId,
            editorState: updatedEditors[currentPageIndex].editorState
          }));

          updatedEditors[nextPageIndex] = { ...updatedEditors[nextPageIndex], editorState: nextEditorState };
          dispatch(updatePage({
            pageId: updatedEditors[nextPageIndex].pageId,
            editorState: updatedEditors[nextPageIndex].editorState
          }));
          currentPageIndex = nextPageIndex;
          currentPage = updatedEditors[currentPageIndex];
          currentPageRawContent = convertToRaw(currentPage.editorState.getCurrentContent());
          currentPageOverflowData = detectOverflow(currentPageRawContent, currentPage.pageId);
        } else {
          let currentEditorState = EditorState.createWithContent(
            convertFromRaw({
              blocks: currentPageOverflowData.visibleBlocks,
              entityMap: currentPageRawContent.entityMap,
            })
          );
          dispatch(updatePage({
            pageId: updatedEditors[currentPageIndex].pageId,
            editorState: currentEditorState
          }));
          addNewPage();
          currentPageOverflowData = null;
        }
      }
    }
    if (contentHasChanged) {
      let combinedHtml = '';
      for (let i = 0; i < pages.length; i++) {
        let page = pages[i];
        const contentState = i === editorIndex ? newEditorState.getCurrentContent() : page.editorState.getCurrentContent();
        const pageHtml = generateHtml(contentState);
        combinedHtml += pageHtml;
      }
      dispatch(insertEditorInstancesDelta(combinedHtml));
      if (!isDidmount) {
        dispatch(updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        debouncedSaveToDatabase(combinedHtml, isUserChange);
      } else {
        setIsDidmount(false);
      }
    }
  };

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result;
        resolve({ data: { link: imageUrl } });
      };
      reader.onerror = () => {
        reject(new Error('Image upload failed.'));
      };
      reader.readAsDataURL(file);
    });
  }
  const stateToText = (contentState) => {
    const rawContentState = convertToRaw(contentState);
    const blocks = rawContentState.blocks;
    return blocks.map((block) => block.text).join('\n');
  };
  // Function to handle the export of the pad's content
  /**
   * useEffect hook that triggers the exportPad function when the `doExport` state is true.
   * Resets the `doExport` state after export is completed.
   */
  useEffect(() => {
    if (doExport) {
      exportPad(doExport);
      dispatch(updateIsExport(''));
    }
  }, [doExport]);

  /**
   * useEffect hook that invokes the handleFileSelect function when the `doFileChoose` state is true.
   * Resets the `doFileChoose` state after file selection is handled.
   */
  useEffect(() => {
    if (doFileChoose) {
      handleFileSelect();
      dispatch(updateIsFileChoose(false));
    }
  }, [doFileChoose]);


  const exportPad = async (doExport) => {
    // Get the current content state of the editor
    const contentState = editorState.getCurrentContent();

    if (doExport == 'txt') {

      let combinedText = '';
      for (const page of pages) {
        const contentState = page.editorState.getCurrentContent();
        const pageText = stateToText(contentState);
        combinedText += pageText;
      }

      // Convert the content state to plain text using a custom function
      // const contentAsText = stateToText(contentState); // Implement stateToText function

      // Create a Blob (Binary Large Object) from the plain text content
      // The Blob represents the data to be saved
      // Set the type to 'text/plain' with charset 'utf-8'
      const blob = new Blob([combinedText], { type: 'text/plain;charset=utf-8' });

      // Use the saveAs function from the file-saver library to trigger a file download
      // Save the Blob with the name 'pad-export.txt'
      saveAs(blob, 'Milepad.txt');
    }

    // Convert content to HTML
    const contentAsHtml = stateToHTML(contentState);
    // console.log('contentAsHtml',contentAsHtml)

    if (doExport == 'pdf') {
      let combinedHtml = '';
      for (const page of pages) {
        const contentState = page.editorState.getCurrentContent();
        const pageHtml = generateHtml(contentState);
        combinedHtml += pageHtml;
      }
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = combinedHtml;
      const targetElement = document.getElementsByClassName('rdw-editor-main')[0];

      // Use html2pdf to convert HTML content to PDF with styles
      html2pdf(tempContainer, {
        margin: 10,
        filename: 'Milepad.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      });
    }

    if (doExport == 'html') {
      let combinedHtml = '';
      for (const page of pages) {
        const contentState = page.editorState.getCurrentContent();
        const pageHtml = generateHtml(contentState);
        combinedHtml += pageHtml;
      }
      const blobHtml = new Blob([combinedHtml], { type: 'text/html;charset=utf-8' });
      saveAs(blobHtml, 'Milepad.html');
    }

  };

  /**
   * Handles the selection of a file and asynchronously reads its content.
   * If successful, updates the editor state with the file content.
   * @async
   */

  const handleFileSelect = async () => {
    if (file) {
      const editorData = await createEditorStateFromDocx(file);
      if (editorData) {
        const editorState = editorData.editorState;
        const editorHtml = editorData.html;
        const pageId = `page-${v4()}`;
        const pagesArray = [{
          pageId: pageId,
          editorState: editorState,
          isActive: true,
        }];
        dispatch(updatePages(pagesArray));
        distributeContentAcrossPages(0, editorState, pagesArray);
        dispatch(loadSignatures([]));
        padServices.saveToDatabase(id, editorHtml, null, dispatch);
      }
      else{
        message.error("Something went wrong...");
      }
    }
  };

  const showAiText = (data) => {

    const activePage = pages.find((page) => page.isActive);

    if (activePage) {
      let editorState = activePage.editorState;
      const currentContent = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      // Convert the new data to ContentState
      const newDataContentState = ContentState.createFromBlockArray(
        convertFromHTML(data)
      );

      // Create a new ContentState by applying the new data to the existing content
      const updatedContentState = Modifier.replaceWithFragment(
        currentContent,
        selection,
        newDataContentState.getBlockMap()
      );

      // Get the current block key
      const currentBlockKey = selection.getStartKey();

      // Get the current block
      const currentBlock = currentContent.getBlockForKey(currentBlockKey);

      // Check if the current block has text and ends with a newline character
      const endsWithNewline = currentBlock.getText().endsWith('\n');

      // Add a newline character if the current block doesn't end with one
      const contentWithNewline = endsWithNewline
        ? updatedContentState
        : Modifier.insertText(updatedContentState, selection, '\n');

      // Update the EditorState with the new ContentState
      const newEditorState = EditorState.push(
        editorState,
        contentWithNewline,
        'insert-fragment'
      );
      // // Set the new EditorState
      // let updatedPages = pages.map((page) => {
      //   if (activePage.pageId === page.pageId) {
      //     page.editorState = newEditorState;
      //   }
      //   return page;
      // });
      // setPages(updatedPages);
      dispatch(updatePage({
        pageId: activePage.pageId,
        editorState: newEditorState
      }));
      setEditorState(newEditorState);
    }
  };

  const detectOverflow = (rawContent, pageId) => {
    const blocks = rawContent.blocks;
    let blockHeight = 0;
    let overflowBlocks = [];
    let visibleBlocks = [];
    let blockElements;
    const editorContainer = document.getElementById(pageId);
    if (editorContainer) {
      blockElements = editorContainer.querySelectorAll('.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr');
    }
    if (blockElements) {
      for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        const blockKey = block.key;
        const matchingElement = Array.from(blockElements).find(element => {
          const offsetKey = element.getAttribute('data-offset-key');
          const elementBlockKey = offsetKey ? offsetKey.slice(0, offsetKey.indexOf('-')) : null;
          return elementBlockKey === blockKey;
        });

        if (matchingElement) {
          blockHeight += getTotalBlockHeight(matchingElement);
          if (blockHeight > 1062) {
            overflowBlocks.push(block);

          } else {
            visibleBlocks.push(block);
          }
        } else {
          // case where blockKey is not found for the current block
        }
      }
    }
    return {
      totalBlockHeight: blockHeight,
      overflowBlocks: overflowBlocks,
      visibleBlocks: visibleBlocks
    }
  }
  const getTotalBlockHeight = (element) => {
    const styles = window.getComputedStyle(element);
    const marginTop = parseFloat(styles['marginTop'] ? styles['marginTop'] : 0);
    // const marginBottom = parseFloat(styles['marginBottom'] ? styles['marginBottom'] : 0);
    const totalHeight = element.clientHeight + marginTop;
    return totalHeight;
  };


  const handleSetActiveEditor = (e, index) => {
    e.preventDefault();
    dispatch(setActivePage({ pageId: pages[index].pageId }));
    // setPages(updatedEditors);
  };

  const addNewPage = () => {
    const newEditor = {
      pageId: `page-${v4()}`,
      editorState: EditorState.createEmpty(),
      isActive: false,
    };
    const lastPageIndex = pages.length - 1;
    const lastPage = pages[lastPageIndex];
    const currentPageRawContent = convertToRaw(lastPage.editorState.getCurrentContent());
    const currentPageOverflowData = detectOverflow(currentPageRawContent, lastPage.pageId);

    if (currentPageOverflowData.overflowBlocks.length > 0) {
      const newPageEditorState = EditorState.createWithContent(
        convertFromRaw({
          blocks: currentPageOverflowData.overflowBlocks,
          entityMap: {},
        })
      );
      newEditor.editorState = newPageEditorState;
    }
    dispatch(addPage(newEditor));
    // setPages(prevEditors => [...prevEditors, newEditor]);
  };

  const removePage = (e, index) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    let updatedPages = [];
    if (index >= 0 && index < pages.length && pages[index]) {
      const pageToDelete = pages[index];
      const nextPageToActivate = index > 0 ? pages[index - 1] : pages[index + 1] || null;
      dispatch(deletePage({ pageId: pageToDelete.pageId }));
      updatedPages = pages.filter(page => page.pageId !== pageToDelete.pageId);
      if (nextPageToActivate && updatedPages.length > 0) {
        dispatch(setActivePage({ pageId: nextPageToActivate.pageId }));
        let combinedHtml = '';
        for (let i = 0; i < updatedPages.length; i++) {
          let page = updatedPages[i];
          const contentState = page.editorState.getCurrentContent();
          const pageHtml = generateHtml(contentState);
          combinedHtml += pageHtml;
        }
        // console.log('okkk updateddPgaes',updatedPages,combinedHtml);
        dispatch(insertEditorInstancesDelta(combinedHtml));
        padServices.saveToDatabase(id, combinedHtml);
      }
    }
  };

  const moveDataToPrevPage = (editorIndex, overflowData, updatedEditors, currentPageRawContent) => {
    const currentPageIndex = editorIndex;
    const nextPageIndex = editorIndex + 1;
    const currentPageBlocks = currentPageRawContent.blocks;
    const nextPage = updatedEditors[nextPageIndex];

    if (!nextPage) {
      console.warn('Next page not found. Skipping data move.');
      return;
    }
    // console.log('okkkinnnn moveDataToPrevPage Function()');

    const nextPageRawContent = convertToRaw(nextPage.editorState.getCurrentContent());
    const nextPageBlocks = nextPageRawContent.blocks;

    let currentPageUpdatedBlocks = [...currentPageBlocks];
    let nextPageUpdatedBlocks = [...nextPageBlocks];
    let currentPageTotalHeight = overflowData.totalBlockHeight;
    let i = 0;

    while (i < nextPageBlocks.length) {
      const block = nextPageBlocks[i];
      const blockElement = document.querySelector(`[data-offset-key="${block.key}-0-0"]`);
      const blockHeight = blockElement ? getTotalBlockHeight(blockElement) : 0;

      if (currentPageTotalHeight + blockHeight < 1062) {
        currentPageUpdatedBlocks = [...currentPageUpdatedBlocks, block];
        currentPageTotalHeight += blockHeight;
        nextPageUpdatedBlocks = nextPageUpdatedBlocks.filter((b) => b.key !== block.key);
      } else {
        break;
      }
      i++;
    }

    if (currentPageUpdatedBlocks.length > currentPageBlocks.length) {

      try {
        const currentEditorState = EditorState.createWithContent(
          convertFromRaw({
            blocks: currentPageUpdatedBlocks,
            entityMap: currentPageRawContent.entityMap,
          })
        );
        const nextEditorState = EditorState.createWithContent(
          convertFromRaw({
            blocks: nextPageUpdatedBlocks,
            entityMap: nextPageRawContent.entityMap,
          })
        );
        updatedEditors[currentPageIndex] = {
          ...updatedEditors[currentPageIndex],
          editorState: currentEditorState,
        };
        updatedEditors[nextPageIndex] = {
          ...updatedEditors[nextPageIndex],
          editorState: nextEditorState,
        };
        dispatch(updatePage({ pageId: updatedEditors[currentPageIndex].pageId, editorState: currentEditorState }));
        dispatch(updatePage({ pageId: nextPage.pageId, editorState: nextEditorState }));
        if (nextPageUpdatedBlocks.length === 0) {
          removePage(null, nextPageIndex);
        }
        return currentEditorState;
      } catch (error) {
        console.error('Error creating EditorState:', error);
        return updatedEditors[currentPageIndex].editorState;
      }
    }
    return updatedEditors[currentPageIndex].editorState;
  };

  const customButton = (
    <div className='custom-toolbarOptions-Container'>
      <TextAlignment activeEditor={activeEditor} />
      <LineSpacingDropdown handleEditorChange={handleEditorChange} />
      {documentInformation.rightType === 2 && (
        <>
          <SignatureModal />
        </>
      )}
    </div>
  );


  const lineHeightClassMap = {
    '1': 'RichEditor-style__line-height-1',
    '1.15': 'RichEditor-style__line-height-15',
    '1.5': 'RichEditor-style__line-height-1-5',
    '2': 'RichEditor-style__line-height-2',
  };

  const alignmentClassMap = {
    'left': 'RichEditor-style__align-left',
    'center': 'RichEditor-style__align-center',
    'right': 'RichEditor-style__align-right',
    'justify': 'RichEditor-style__align-justify',
  };

  const blockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    const blockData = contentBlock.getData();
    let cls = '';
    switch (type) {
      case 'unstyled':
        cls = 'RichEditor-style__initial-unstyled';
        break;
      case 'atomic':
        cls = 'RichEditor-style__initial-atomic';
        break;
      default:
        break;
    }
    const lineHeight = blockData.get('lineHeight');
    const textAlignment = blockData.get('textAlignment');
    if (lineHeight) {
      const lineHeightClass = lineHeightClassMap[lineHeight]
        ? lineHeightClassMap[lineHeight]
        : `RichEditor-style__line-height-${lineHeight.toString().replace('.', '-')}`;
      cls += ` ${lineHeightClass}`;
      createLineHeightClass(lineHeight, lineHeightClass);
    }
    if (textAlignment) {
      const alignmentClass = alignmentClassMap[textAlignment];
      // console.log('okkk alignmentClass', alignmentClass, '\ntextAlignment', textAlignment, '\nblock', contentBlock.toJS());
      cls += ` ${alignmentClass}`;
    }
    return cls;
  };

  const createLineHeightClass = (lineHeight, className) => {
    const style = document.createElement('style');
    style.innerHTML = `.${className} { line-height: ${lineHeight}; }`;
    document.head.appendChild(style);
  };

  return (
    <div>
      {user.rightType == 2 &&
        <Button className='AI-icon' title='Generate with AI' type="text" icon={<Ai onClick={() => { setAiModalVisibility(true) }} />} />
      }
      <AiTextButton
        isOpen={isOpenAi}
        setIsOpenAi={setIsOpenAi}
        showAiText={showAiText} setAiModalVisibility={setAiModalVisibility} aiModalVisibility={aiModalVisibility}
      />
      <div id='my-editor-main-container' className='main-editor-container'>
        {pages.map((editorData, index) => (
          <div
            id={editorData.pageId}
            key={editorData.pageId}
            style={{ marginBottom: '20px', height: 'fit-content', position: 'relative' }}
            className={`${classes.editor} editor-page`}
            onClick={(e) => handleSetActiveEditor(e, index)}
          // onMouseEnter={() => handleSetActiveEditor(index)}
          >
            {index !== 0 && !isReadOnly &&
              <div className='deletePage-icon' style={{ textAlign: 'right', marginTop: '-21px', marginRight: '-16px' }}>
                <img style={{ cursor: 'pointer' }} src={deleteIcon} width='14px' onClick={(e) => removePage(e, index)} />
              </div>
            }
            <Editor
              // ref={editorRef}
              id={editorData.pageId}
              editorState={editorData.editorState}
              onEditorStateChange={(newEditorState) => handleEditorChange(index, newEditorState)}
              readOnly={isReadOnly ? true : false}
              toolbarHidden={isReadOnly || !editorData.isActive}
              toolbarCustomButtons={[customButton]}
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  alt: { present: true, mandatory: false },
                  previewImage: true,
                  "previewImage": true,
                },
              }}
              keyBindingFn={(e) => {
                if (e.keyCode === 8) {
                  setIsDeleteKeyPressed(true);
                }
                else {
                  setIsDeleteKeyPressed(false);
                }
                return getDefaultKeyBinding(e);
              }}
              blockStyleFn={blockStyleFn}
            />
            <Signatures pageIndex={index} />
          </div>
        ))}
      </div >

    </div>
  );
};

export default React.memo(RichTextEditor);
export const TONE_ENUMS = {
    FORMAL: "Formal",
    INFORMAL: "Informal",
    OPTIMISTIC: "Optimistic",
    WORRIED: "Worried",
    FRIENDLY: "Friendly",
    CURIOUS: "Curious",
    ASSERTIVE: "Assertive",
    ENCOURAGING: "Encouraging",
    SURPRISED: "Surprised",
    COOPERATIVE: "Cooperative",
}